import React from 'react'
import Landing from '../components/landing/landing'
import Footer from '../components/footer/footer'
import { graphql } from 'gatsby'
import SEO from '../components/common/seo'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Marketing = ({ data }) => (
  <div className="container-fluid">
    <SEO />
    <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
    <Landing content={data.datoCmsLandingPage.content} />
    <Footer />
  </div>
)

export default Marketing

export const query = graphql`
  query Landing {
    datoCmsLandingPage {
      content
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
  }
`
